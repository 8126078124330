// RectangularView.js
import React from 'react';
import './RectangularView.css'; // Import your CSS file
import styles from "./Tooltips.css"

function generateArcPoints(radius, centerX, centerY, numPoints) {
  const points = [];

  for (let i = 0; i < numPoints; i++) {
    const angle = Math.PI/2 + (i / (numPoints - 1)) * Math.PI; // Adjust the factor to control the arc length
    const x = centerX + radius * Math.cos(angle);
    const y = centerY + radius * Math.sin(angle);
    points.push({ x, y });
  }

  return points;
}




const RectangularView = () => {
  // Generate random coordinates for the image
  const randomX = Math.floor(Math.random() * 250); // Adjust the maximum value based on the width of your view
  const randomY = Math.floor(Math.random() * 150); // Adjust the maximum value based on the height of your view
  const randomX2 = Math.floor(Math.random() * 250); // Adjust the maximum value based on the width of your view
  const randomY2 = Math.floor(Math.random() * 150); // Adjust the maximum value based on the height of your view


  // Example usage
  const radius = 60; // Adjust the radius of the circle
  const centerX = 30; // Adjust the x-coordinate of the circle's center
  const centerY = 50; // Adjust the y-coordinate of the circle's center
  const numPoints = 5; // Number of points to generate along the arc

  const arcPoints = generateArcPoints(radius, centerX, centerY, numPoints);
  console.log(arcPoints);



  // Define the image source
  const imageUrl = 'logo192.png'; // Replace with your image URL
  // Define the inline styles for the rectangular view
  const rectangularViewStyles = {
    position: 'relative',
  };

  // Define the inline styles for the image
  const imageStyles = {
    position: 'absolute',
    top: `${randomY}px`,
    left: `${randomX}px`,
    width: '100px', // Set the width of the image
    height: '100px', // Set the height of the image

  };
  // Define the inline styles for the image
  const imageStyles2 = {
    position: 'absolute',
    top: `${randomY2}px`,
    left: `${randomX2}px`,
    width: '100px', // Set the width of the image
    height: '100px', // Set the height of the image
  };


    const sampleTextArray = ['Fortitude', 'Balance', 'Karma', 'Energy', 'Willpower'];



  return (
    <div className="rectangular-view" style={rectangularViewStyles}>
      {/* Your content goes here */}


            {arcPoints.map((point, index) => (

              <div key={index} style={{ position: 'absolute', top: `${point.y}px`, left: `${point.x}px` }}>

              <img
                key={index}
                src="logo192.png" // Replace with your image URL
                alt={`Image ${index + 1}`}
                title={sampleTextArray[4-index]} // Add a tooltip with the corresponding text
                style={{
                  position: 'absolute',
                  top: `${point.y}px`,
                  left: `${point.x}px`,
                  width: '50px', // Set the width of the image
                  height: '50px', // Set the height of the image

                }}
              />



      <div id="texts" style={{ position: 'absolute', display: "inline", whiteSpace: "nowrap" ,top: `${point.y+10}px`, left: `${point.x+50}px` }}>

          <div class="tooltip">
              <span class="tooltiptext">{sampleTextArray[4-index]}</span>
                      <strong> {4-index} {sampleTextArray[4-index]}</strong>


            </div>
          <a
          className="App-link"
          href="https://www.spphotonic.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {sampleTextArray[4-index]}
        </a>


      </div>
      </div>


            ))}




    </div>
  );
}

export default RectangularView;