// RectangularView.js
import React from 'react';
import './RectangularView.css'; // Import your CSS file




const HeaderView = () => {
  const randomX = Math.floor(Math.random() * 250); // Adjust the maximum value based on the width of your view


  
  return (
        <p>
          SPPhotonic <code> Fantastic Engineering Projects</code>
        </p>
  );
}

export default HeaderView;