import React, { Component }  from 'react';
import logo from './logo.svg';
import './App.css';
import TooltipContainer from './components/TooltipContainer';
import Tierbox from './components/Tierbox';
import RectangularView from './components/RectangularView';
import HeaderView from './components/HeaderView';
import RectReverse from './components/RectReverse';
function App() {
  return (
    <div className="App">
      <header className="App-header">

        <HeaderView/>
        <RectangularView/>
        <RectReverse/>
        <HeaderView/>

      </header>
    </div>
  );
}

export default App;
